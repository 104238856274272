.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  // left: 0;
  right: 0;
  // background-color: rgba($color: #000000, $alpha: 0.35);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  .modal {
    width: 30vw;
    height: auto;
    background-color: #fff;
    padding: 2rem;
    border-radius: 20px;

    transform: translateY(-600px);
    transition: all 0.4s ease;

    background-color: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

    &_header {
      position: relative;
      border-bottom: 1px solid #dddddd;
      &-title {
        color: #293461;
        margin-bottom: 10px;
        text-align: center;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        border: 1px solid #fff;

        img {
          width: 1rem;
          height: auto;
          transition: all 0.3s;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

    &_content {
      border-bottom: 1px solid #dddddd;
      justify-content: flex-start;
      padding-bottom: 18px;
      // padding: 2rem 0;

      .input {
        .MuiOutlinedInput-root {
          height: 32px !important;
        }
        .MuiOutlinedInput-input {
          font-size: 12px !important;
          font-family: openSans-SemiBold !important;
        }
      }
      .inputContainer {
        margin-top: 10px;
        display: flex;
      }
    }

    &_footer {
      padding: 2rem 0;
      padding-bottom: 0;

      button {
        float: right;
        padding: 0.5rem;

        border-radius: 8px;
      }
      .modal-close {
        cursor: pointer;
        background-color: #ffadb0;
        font-weight: 600;
        border: 1px solid #ffadb0;

        &:hover {
          color: rgba(54, 67, 72, 0.8);
        }
      }
      .submit {
        cursor: pointer;
        font-family: openSans-SemiBold;
        margin-right: 1rem;
        padding: 7px;
        background-color: #00838c;
        border: 1px solid #00838c;
        color: #fff;

        &:hover {
          background-color: rgba(54, 67, 72, 0.8);
        }
      }
    }
  }
}

.modalContainer.show {
  visibility: visible;
  opacity: 1;

  .modal {
    transform: translateY(0);
  }
}
